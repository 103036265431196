import { ToastVariant } from "@taxbit-private/cosmic";
import { StringType } from "@taxbit-private/type-wrappers";

export type ToastUuid = StringType<"ToastUuid">;

export enum ToastType {
  All = "ALL",
  Source = "SOURCE",
}

export type ToastConfig = {
  /**
   * The content that will appear in the toast.
   */
  content: React.ReactElement | string;
  /**
   * Determines whether or not the toast should auto hide
   */
  autoHide?: boolean;
  /**
   * A number indicating the time (in milliseconds) that a toast should
   * appear on screen before it disappears. If `timeoutMs` is not defined,
   * a toast will persist until a user explicitly closes it.
   */
  timeoutMs?: number;
  /**
   * The style variant of the toast. Defaults to "primary".
   */
  variant?: ToastVariant;
  /**
   * A callback function that will be called when the toast is closed in addition to removeToast.
   */
  onClose?: () => void;
  /**
   * A string indicating the type of toast. This can be used to filter toasts to remove by type.
   */
  type?: ToastType;
};

export type Toast = ToastConfig & { uuid: ToastUuid };

export type ToastSlice = {
  /**
   * The list of toasts currently appearing globally in the UI. These toasts
   * should appear on the bottom of the screen.
   */
  toasts: Toast[];
  /**
   * Adds a global toast notification to the UI.
   */
  addToast: (toast: ToastConfig) => void;
  /**
   * Removes the global toast notification with the given UUID from the UI.
   */
  removeToast: (uuid: ToastUuid) => void;
  /**
   * Clears all current toast notifications.
   */
  clearToasts: (toastType?: ToastType) => void;
};
