import { Auth } from "@aws-amplify/auth";

const signOut = () => {
  Auth.signOut().catch((error: unknown) =>
    // eslint-disable-next-line no-console
    console.error("Error logging out:", error)
  );
};

export const useAuthentication = () => {
  return { signOut };
};
