import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  FileUpload,
  GetFilesResponse,
} from "../api/endpoints/sources/fileGetApiTypes";
import {
  isSourceStatusInProgress,
  isStatusDeleteInProgress,
} from "../api/endpoints/sources/utils/sourceStatus";
import { isWallet } from "../pages/source-view/utils/isWallet";

export enum BannerMessageType {
  SingleFileIngesting = "SingleFileIngesting",
  SingleWalletIngesting = "SingleWalletIngesting",
  MultipleSourcesIngesting = "MultipleSourcesIngesting",
  SingleSourceDeleting = "SingleSourceDeleting",
  MultipleSourcesDeleting = "MultipleSourcesDeleting",
  SourcesDeletingAndIngesting = "SourcesDeletingAndIngesting",
  TaxCalcsInProgress = "TaxCalcsInProgress",
}

const getSingleSourceName = (source: FileUpload) => {
  if (isWallet(source.sourceType)) {
    return source.walletAddress ?? source.label;
  }
  return source.fileName ?? source.label;
};

export const getBannerMessageType = (sourcesInProgress: GetFilesResponse) => {
  const sourcesDeleting = sourcesInProgress.filter((source) =>
    isStatusDeleteInProgress(source.status)
  );
  const sourcesIngesting = sourcesInProgress.filter((source) =>
    isSourceStatusInProgress(source.status)
  );
  if (sourcesDeleting.length > 0 && sourcesIngesting.length > 0) {
    return BannerMessageType.SourcesDeletingAndIngesting;
  }
  if (sourcesDeleting.length > 0) {
    return sourcesDeleting.length === 1
      ? BannerMessageType.SingleSourceDeleting
      : BannerMessageType.MultipleSourcesDeleting;
  }
  if (sourcesIngesting.length > 0) {
    if (sourcesIngesting.length === 1) {
      return isWallet(sourcesIngesting[0]?.sourceType)
        ? BannerMessageType.SingleWalletIngesting
        : BannerMessageType.SingleFileIngesting;
    }
    return BannerMessageType.MultipleSourcesIngesting;
  }
  return BannerMessageType.TaxCalcsInProgress;
};

export type UseGetBannerMessageResult = {
  getBannerMessage: (sourcesInProgress: GetFilesResponse) => string;
};

export const useGetBannerMessage = (): UseGetBannerMessageResult => {
  const { t } = useTranslation();

  return {
    getBannerMessage: useCallback(
      (sourcesInProgress: GetFilesResponse) => {
        const messageType = getBannerMessageType(sourcesInProgress);
        const sourceName =
          sourcesInProgress[0] && getSingleSourceName(sourcesInProgress[0]);

        switch (messageType) {
          case BannerMessageType.SingleFileIngesting: {
            return t(messageType, {
              fileName: sourceName,
            });
          }
          case BannerMessageType.SingleWalletIngesting: {
            return t(messageType, {
              walletAddress: sourceName,
            });
          }
          case BannerMessageType.SingleSourceDeleting: {
            return t(messageType, {
              sourceName,
            });
          }
          default: {
            return t(messageType);
          }
        }
      },
      [t]
    ),
  };
};
