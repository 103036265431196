import { Amplify } from "@aws-amplify/core";
import { Authenticator } from "@aws-amplify/ui-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GlobalStyles, highContrastTheme } from "@taxbit-private/cosmic";
import { CosmicLocalizationContextProvider } from "@taxbit-private/cosmic-localization";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  DefaultTheme,
  StyleSheetManager,
  ThemeProvider,
} from "styled-components";

import { App } from "./App";
import I18nWrapper from "./i18n";
import initializeMockWorker from "./mocks/initializeMockWorker";
import { isCommercialEnvironment } from "./utils/environment";
import queryClientOptions from "./utils/queryClientOptions";
import { initializeDatadog } from "./vendors/datadog";

// Imports CSS files that provides fonts and styles for external libraries
import "@taxbit-private/cosmic/dist/style.css";

const queryClient = new QueryClient(queryClientOptions);
void initializeMockWorker();
const redirectSignInUri = window.location.origin;

const redirectSignOutUri = isCommercialEnvironment()
  ? window.location.origin
  : `https://idp.int.identitysandbox.gov/openid_connect/logout?client_id=${
      import.meta.env.VITE_REACT_APP_LOGIN_GOV_CLIENT_ID
    }&post_logout_redirect_uri=${window.location.origin}`;

Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_REACT_APP_AWS_REGION,
    userPoolId: import.meta.env.VITE_REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env
      .VITE_REACT_APP_COGNITO_USER_POOL_APP_CLIENT_ID,
  },
  oauth: {
    domain: import.meta.env.VITE_REACT_APP_COGNITO_USER_POOL_DOMAIN,
    scope: ["email", "openid"],
    responseType: "code",
    redirectSignIn: redirectSignInUri,
    redirectSignOut: redirectSignOutUri,
  },
});

window.appVersion = APP_VERSION;

if (isCommercialEnvironment()) {
  initializeDatadog();
}

const root = ReactDOM.createRoot(
  document.querySelector("#root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <StyleSheetManager disableVendorPrefixes={true}>
      <ThemeProvider theme={highContrastTheme as unknown as DefaultTheme}>
        <GlobalStyles />
        <QueryClientProvider client={queryClient}>
          <Authenticator.Provider>
            <CosmicLocalizationContextProvider>
              <I18nWrapper>
                <App />
              </I18nWrapper>
            </CosmicLocalizationContextProvider>
          </Authenticator.Provider>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
        </QueryClientProvider>
      </ThemeProvider>
    </StyleSheetManager>
  </React.StrictMode>
);
