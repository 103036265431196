import { FileUpload, SourceStatus } from "../fileGetApiTypes";

export type ProcessingSourceStatus =
  | SourceStatus.Initialized
  | SourceStatus.SyncInProgress
  | SourceStatus.NormalizationInProgress
  | SourceStatus.FileIngestionInProgress
  | SourceStatus.TransferDetectionInProgress;

export type FailedProcessingSourceStatus =
  | SourceStatus.SyncFailed
  | SourceStatus.NormalizationFailed
  | SourceStatus.FileIngestionFailed
  | SourceStatus.TransferDetectionFailed;

export type FailedDeleteSourceStatus = SourceStatus.DeleteFailed;

export const isSourceStatusInProgress = (status: FileUpload["status"]) =>
  isStatusInitialized(status) ||
  isStatusNormalizationInProgress(status) ||
  isStatusSyncInProgress(status) ||
  isStatusFileIngestionInProgress(status) ||
  isStatusTransferDetectionInProgress(status);

export const isStatusProcessingFailed = (status: FileUpload["status"]) =>
  isStatusNormalizationFailed(status) ||
  isStatusSyncFailed(status) ||
  isStatusFileIngestionFailed(status) ||
  isStatusTransferDetectionFailed(status);

export const isStatusInitialized = (status: FileUpload["status"]) =>
  [SourceStatus.Initialized].includes(status as SourceStatus);

export const isStatusSyncInProgress = (status: FileUpload["status"]) =>
  [SourceStatus.SyncInProgress].includes(status as SourceStatus);

const isStatusSyncFailed = (status: FileUpload["status"]) =>
  [SourceStatus.SyncFailed].includes(status as SourceStatus);

const isStatusNormalizationInProgress = (status: FileUpload["status"]) =>
  [SourceStatus.NormalizationInProgress].includes(status as SourceStatus);

export const isStatusNormalizationFailed = (status: FileUpload["status"]) =>
  [SourceStatus.NormalizationFailed].includes(status as SourceStatus);

const isStatusFileIngestionInProgress = (status: FileUpload["status"]) =>
  [SourceStatus.FileIngestionInProgress].includes(status as SourceStatus);

const isStatusFileIngestionFailed = (status: FileUpload["status"]) =>
  [SourceStatus.FileIngestionFailed].includes(status as SourceStatus);

export const isStatusTransferDetectionInProgress = (
  status: FileUpload["status"]
) =>
  [SourceStatus.TransferDetectionInProgress].includes(status as SourceStatus);

const isStatusTransferDetectionFailed = (status: FileUpload["status"]) =>
  [SourceStatus.TransferDetectionFailed].includes(status as SourceStatus);

export const isStatusDeleteInProgress = (status: FileUpload["status"]) =>
  [SourceStatus.DeleteInProgress].includes(status as SourceStatus);

export const isStatusDeleteFailed = (status: FileUpload["status"]) =>
  [SourceStatus.DeleteFailed].includes(status as SourceStatus);

export const isStatusReady = (status: FileUpload["status"]) =>
  [SourceStatus.Ready].includes(status as SourceStatus);

export const isStatusConverted = (status: FileUpload["status"]) =>
  [SourceStatus.Converted].includes(status as SourceStatus);
