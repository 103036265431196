import { Toast } from "@taxbit-private/cosmic";
import { useCallback, useEffect, useRef } from "react";

import { Toast as ToastType, ToastUuid } from "../../store/toastSliceModels";
import useDartsStore from "../../store/useDartsStore";

const TOAST_DEFAULT_TIMEOUT = 5000;

const TimedToast: React.FC<ToastType> = ({
  uuid,
  content,
  variant,
  timeoutMs,
  autoHide = true,
  onClose,
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const toastRef = useRef<HTMLDivElement>(null);

  const removeToast = useDartsStore((state) => state.removeToast);

  const handleClose = useCallback(
    (toastUuid: ToastUuid) => {
      if (onClose) {
        onClose();
        removeToast(toastUuid);
      } else {
        removeToast(toastUuid);
      }
    },
    [onClose, removeToast]
  );

  useEffect(() => {
    if (toastRef.current) {
      toastRef.current.focus();
    }
    if (autoHide || timeoutMs) {
      const toastTimeoutMs = timeoutMs ?? TOAST_DEFAULT_TIMEOUT;
      timeoutRef.current = setTimeout(() => handleClose(uuid), toastTimeoutMs);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutMs, uuid, autoHide, handleClose]);

  return (
    <div ref={toastRef} tabIndex={-1}>
      <Toast
        key={uuid}
        onClose={() => handleClose(uuid)}
        variant={variant}
        trackingId="toast"
      >
        {content}
      </Toast>
    </div>
  );
};

export default TimedToast;
