export enum HttpErrorCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  InternalServerError = 500,
}

class HttpError extends Error {
  public statusCode: HttpErrorCode;

  public message: string;

  constructor(statusCode: HttpErrorCode, message: string) {
    super(message);
    this.statusCode = statusCode;
    this.message = message;
  }
}

export default HttpError;
