import { z } from "zod";

import { getAccessToken } from "./utils/getAccessToken";
import { getHeaders } from "./utils/getHeaders";
import { getRequestBody } from "./utils/getRequestBody";
import isErrorResponse from "./utils/handleCustomErrorResponse";
import HttpError, { HttpErrorCode } from "./utils/httpError";
import { JsonData, JsonSchema } from "./utils/jsonDataTypes";
import { ProjectError } from "../utils/ApiError";

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

type MakeRequestOptions<T extends JsonSchema, U extends JsonSchema> = {
  baseUrl: string;
  requestHeaders?: Headers;
  urlPath?: string;
  method?: HttpMethod;
  urlSearchParams?: URLSearchParams;
  requestData?: JsonData | FormData;
  responseDataSchema?: T;
  requestDataSchema?: U;
};

// A "conditional type", which can infer whether or not a reponseDataSchema is provided
type ResponseTypes<T> = T extends { responseDataSchema: JsonSchema }
  ? z.infer<T["responseDataSchema"]>
  : undefined;

const makeApiRequest = async <
  T extends MakeRequestOptions<JsonSchema, JsonSchema>
>({
  baseUrl,
  requestHeaders,
  urlPath,
  method,
  urlSearchParams,
  requestData,
  responseDataSchema,
  requestDataSchema,
}: T): Promise<ResponseTypes<T>> => {
  const token = await getAccessToken();

  const headers = getHeaders(requestHeaders, token);
  const body = getRequestBody(requestData, requestDataSchema);

  if (body && requestDataSchema && !headers.get("content-type"))
    headers.set("content-type", "application/json");

  const queryString = urlSearchParams ? `?${urlSearchParams}` : "";
  const urlWithPath = urlPath ? `${baseUrl}/${urlPath}` : baseUrl;
  const fullUrl = `${urlWithPath}${queryString}`;

  const response = await fetch(fullUrl, {
    method,
    headers,
    body,
  }).catch((e: unknown) => {
    throw new Error(`Network error calling ${baseUrl}: ${JSON.stringify(e)}`);
  });

  // The below type casts are currently a limitation on the typescript compiler
  // Using unspecified generic types with a conditional type, it cannot infer
  // Issue details here: https://github.com/microsoft/TypeScript/issues/33912
  // Helpful discussion here: https://stackoverflow.com/a/66553240
  if (responseDataSchema) {
    const res = (await response.json()) as z.infer<typeof responseDataSchema>;

    if (
      (response.status as HttpErrorCode) === HttpErrorCode.BadRequest &&
      isErrorResponse(res)
    ) {
      throw new ProjectError({
        errors: res.errors,
        message: res.errorMessage,
        statusCode: HttpErrorCode.BadRequest,
      });
    } else if (!response.ok) {
      if ((response.status as HttpErrorCode) === HttpErrorCode.Forbidden) {
        // Redirect to the unauthorized page
        window.location.href = "/unauthorized";
      } else if (
        (response.status as HttpErrorCode) === HttpErrorCode.Conflict
      ) {
        throw new HttpError(
          HttpErrorCode.Conflict,
          "There was a conflict with your request."
        );
      } else {
        throw new Error(
          `There was an error with your request, please try again.`
        );
      }
    }

    return responseDataSchema.parse(res) as ResponseTypes<T>;
  } else {
    // Intentionally return undefined if a responseDataSchema is not provided
    return undefined as ResponseTypes<T>;
  }
};

export default makeApiRequest;
