import { EnumLike, z } from "zod";

const unknownValuesEncountered = new Set();

const logUnknownValueEncounteredMessage = (value: string) => {
  if (!unknownValuesEncountered.has(value)) {
    // eslint-disable-next-line no-console
    console.warn(
      `An unknown enumeration value of "${value}" was received from the TaxBit server. ` +
        `If you are using the TaxBit SDK, upgrading to the latest version is advised.`
    );
    unknownValuesEncountered.add(value);
  }
};

type LaxEnumLike = {
  Unknown: "Unknown";
} & EnumLike;
/**
 * This creates a Zod schema that acts like z.enum in that it checks to see if the
 * value being validated is found in the array of allowed values. This function behaves
 * differently, however, in that if the value is NOT found in the array of allowed
 * values, the value will be converted to UNKNOWN and will pass validation.
 *
 * When a type is inferred from this schema, it will be a string union that will include
 * all values passed into this function plus the string UNKNOWN.
 *
 * This can be particularly useful if we need to allow for new enum values to be sent from the
 * server in the future. Enum value additions on data returned from the server are often
 * considered to be backward-compatible, so the API may not be versioned when the enum value
 * is added. It can be argued whether adding an enum value should be considered a breaking
 * change to the server API.
 */
// This used the zod.nativeEnum function vs the enum function
const zodLaxNativeEnum = <T extends LaxEnumLike>(values: T) => {
  const enumSchema = z.nativeEnum(values);
  return z.preprocess((value) => {
    if (typeof value !== "string") {
      return value;
    }
    const isKnownValue = enumSchema.safeParse(value).success;
    if (isKnownValue) {
      return value;
    } else {
      logUnknownValueEncounteredMessage(value);
      return values.Unknown;
    }
  }, enumSchema);
};

export default zodLaxNativeEnum;
