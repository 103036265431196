const initializeMockWorker = async () => {
  // Initialize the mock worker for an improved developer experience
  if (process.env.NODE_ENV === "development") {
    // The worker is only needed when we are actually starting it, but we
    // just leave it set up for easiest usage when desired.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { worker } = await import("./browser");
    // await worker.start();
  }
};

export default initializeMockWorker;
