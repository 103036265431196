import { useQuery, useQueryClient } from "@tanstack/react-query";

import { Case, caseSchema } from "./caseTypes";
import { getBaseCaseApiUrl } from "../../../utils/environment";
import makeApiRequest from "../../makeApiRequest";
import pathBuilders from "../../utils/pathBuilder";
import queryKeys from "../../utils/queryKeys";

export const useGetCase = (caseId: Case["id"]) => {
  const queryClient = useQueryClient();
  const baseUrl = getBaseCaseApiUrl();

  return useQuery(
    queryKeys.getCaseKey(caseId),
    () => {
      return makeApiRequest({
        baseUrl,
        urlPath: pathBuilders.buildCasePath(caseId),
        responseDataSchema: caseSchema,
      });
    },
    {
      initialData: () => {
        const queryData = queryClient.getQueryData<Case[]>(
          queryKeys.getCasesKey()
        );
        return queryData?.find((caseItem) => caseItem.id === caseId);
      },
    }
  );
};
