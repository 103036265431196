import { Backdrop, BlurContainer, StaticModal } from "@taxbit-private/cosmic";
import { useState } from "react";

import { useAuthentication } from "../auth/useAuthentication";
import { useIsCiTenant } from "../utils/hooks/useIsCiTenant";

type CiTermsOfUsePopupProps = {
  children: React.ReactNode;
};

/**
 * This wrapper shows a popup with the CI terms of use when the user is logged into the criminal investigation tenant environment.
 */
const CiTermsOfUseWrapper: React.FC<CiTermsOfUsePopupProps> = ({
  children,
}) => {
  const [showCiTermsModal, setShowCiTermsModal] = useState(true);
  const isCiTenant = useIsCiTenant();
  const { signOut } = useAuthentication();

  const shouldShowPopup = isCiTenant && showCiTermsModal;

  if (!shouldShowPopup) {
    return <>{children}</>;
  }

  return (
    <>
      <Backdrop />
      <StaticModal
        tracking-id="ci-terms-modal-popup"
        title="Criminal Investigation Terms of Use"
        primaryButtonProps={{
          label: "Acknowledge",
          trackingId: "ci-terms-modal-acknowledge-button",
          onClick: () => setShowCiTermsModal(false),
        }}
        secondaryButtonProps={{
          label: "Decline and Log Out",
          trackingId: "ci-terms-modal-decline-button",
          onClick: signOut,
        }}
      >
        This software platform contains trade secrets and confidential business
        or financial information covered by the Trade Secrets Act, 18 U.S.C. §
        1905, and thus exempt from disclosure under the Freedom of Information
        Act, 5 U.S.C. §552. Such trade secrets and confidential business or
        financial information shall not be disclosed outside the Internal
        Revenue Service and shall not be duplicated, used, or disclosed—in whole
        or in part—for any purpose other than to evaluate this software
        platform.
      </StaticModal>
      <BlurContainer>{children} </BlurContainer>
    </>
  );
};

export default CiTermsOfUseWrapper;
