import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { ContentSpinner } from "@taxbit-private/cosmic";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useDartsStore from "../store/useDartsStore";
import { isGovCloudEnvironment } from "../utils/environment";

const FISMA_WARNING_MESSAGE =
  "WARNING! This system contains U.S. Government information. By accessing and using this computer system, you are consenting to system monitoring for law enforcement and other purposes. Unauthorized use of, or access to, this computer system may subject you to state and federal criminal prosecution and penalties as well as civil penalties.";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();

  // The context callback is meant to help prevent unnecessary rerenders
  const { route } = useAuthenticator((context) => [context.route]);

  const addToast = useDartsStore((state) => state.addToast);

  // Hub listener hook that will respond to any auth events
  useEffect(() => {
    const hubListenerCancelToken = Hub.listen(
      "auth",
      ({ payload: { event, data } }) => {
        if (event === "signIn" && isGovCloudEnvironment()) {
          addToast({
            content: FISMA_WARNING_MESSAGE,
            variant: "primary",
            autoHide: false,
          });
        } else if (event === "customOAuthState") {
          navigate(data as string, { replace: true });
        }
      }
    );

    // Always return a cleanup function to prevent memory leaks
    // In this case, Hub.listen returns the function to unsubscribe from the event
    return hubListenerCancelToken;
  }, [addToast, navigate]);

  // Effect hook which handles the authentication flow
  useEffect(() => {
    const authenticate = async () => {
      if (route === "setup") {
        // Use this to show the Cognito Hosted UI
        await Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Cognito,
          customState: window.location.pathname,
        });
      }
    };

    authenticate().catch((error: unknown) => {
      let message = `Error authenticating the user`;
      if (error instanceof Error) {
        message += `: ${error.message}`;
      }
      throw new Error(message);
    });
  }, [route]);

  if (route === "idle" || route === "setup") {
    return <ContentSpinner trackingId="require-auth-spinner" />;
  }
  return children;
};

export default RequireAuth;
