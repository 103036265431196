import { useQuery } from "@tanstack/react-query";

import { Report, ReportStatus, getReportsSchema } from "./reportsGetApiTypes";
import { getBaseReportUrl } from "../../../utils/environment";
import makeApiRequest from "../../makeApiRequest";
import pathBuilders from "../../utils/pathBuilder";
import queryKeys from "../../utils/queryKeys";
import { Case } from "../cases/caseTypes";

type getReportsParams = {
  caseId: Case["id"];
};

const REPORT_POLLING_IN_MS = 3_000;

const isAnyReportInProgress = (reports: Report[] | undefined) =>
  reports?.some((report) => isReportStatusInProgress(report.status));

export const isReportStatusInProgress = (status: Report["status"]) =>
  [ReportStatus.Initialized, ReportStatus.InProgress].includes(
    status as ReportStatus
  );

export const getReports = async ({ caseId }: getReportsParams) => {
  const baseUrl = getBaseReportUrl();

  return makeApiRequest({
    baseUrl,
    urlPath: caseId && pathBuilders.buildReportsPath(caseId),
    responseDataSchema: getReportsSchema,
  });
};

export const useGetReports = (caseId: Case["id"]) => {
  return useQuery({
    queryKey: queryKeys.getReportsKey(caseId),
    queryFn: () => getReports({ caseId }),
    refetchInterval: (reports) =>
      isAnyReportInProgress(reports) ? REPORT_POLLING_IN_MS : false,
  });
};
