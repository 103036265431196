import { z } from "zod";

export enum CaseStatus {
  Idle = "IDLE",
  FileIngestionInProgress = "FILE_INGESTION_IN_PROGRESS",
  TaxEngineInProgress = "TAX_ENGINE_IN_PROGRESS",
  TransferDetectionInProgress = "TRANSFER_DETECTION_IN_PROGRESS",
  TransferDetectionFailed = "TRANSFER_DETECTION_FAILED",
  TaxEngineFailed = "TAX_ENGINE_FAILED",
  // To be removed once TSS is no longer used to trigger tax calcs
  TaxEngineScheduled = "TAX_ENGINE_SCHEDULED",
}

export const caseStatusSchema = z.object({
  caseStatus: z.nativeEnum(CaseStatus),
});

export type CaseStatusEnum = z.infer<typeof caseStatusSchema>;
