import { useQuery } from "@tanstack/react-query";

import { tenantMetadataSchema } from "./tenantMetadataTypes";
import { Asset, assetSchema } from "../../../types/data-models-v2/asset";
import { getBaseCaseApiUrl } from "../../../utils/environment";
import makeApiRequest from "../../makeApiRequest";
import pathBuilders from "../../utils/pathBuilder";
import queryKeys from "../../utils/queryKeys";

export const setSessionLocale = (locale: string) => {
  sessionStorage.setItem("locale", locale);
};

export const setSessionCurrency = (currency: Asset) => {
  sessionStorage.setItem("currency", JSON.stringify(currency));
};

export const useGetTenantMetadata = () => {
  const baseUrl = getBaseCaseApiUrl();

  return useQuery(queryKeys.getTenantKey(), async () => {
    const res = await makeApiRequest({
      baseUrl,
      urlPath: pathBuilders.buildTenantMetadataPath(),
      responseDataSchema: tenantMetadataSchema,
    });

    const sessionLocale = sessionStorage.getItem("locale");
    const sessionCurrency = sessionStorage.getItem("currency");

    return tenantMetadataSchema.parse({
      ...res,
      locale: sessionLocale ?? res.locale,
      preferredFiatAsset:
        sessionCurrency &&
        assetSchema.safeParse(JSON.parse(sessionCurrency)).success
          ? assetSchema.parse(JSON.parse(sessionCurrency))
          : res.preferredFiatAsset,
    });
  });
};
