import { z } from "zod";

export enum ImputationMethodEnum {
  Taxable = "taxable-set-as-income-or-expense",
  NonTaxable = "non-taxable-keep-as-transfer",
}

const imputationMethodSchema = z.nativeEnum(ImputationMethodEnum);

export const customTxnRulesSchema = z.object({
  imputation: imputationMethodSchema,
});

export type GetImputationMethodsResponse = {
  id: ImputationMethodEnum;
  label: string;
}[];
