import { z } from "zod";

import { FileRequestSourceType } from "./subpoenaApiTypes";
import zodLaxEnum from "../../../types/zodEnum";

export enum SourceStatus {
  Initialized = "INITIALIZED",
  Ready = "READY",
  SyncInProgress = "SYNC_IN_PROGRESS",
  SyncFailed = "SYNC_FAILED",
  NormalizationInProgress = "NORMALIZATION_IN_PROGRESS",
  NormalizationFailed = "NORMALIZATION_FAILED",
  FileIngestionInProgress = "FILE_INGESTION_IN_PROGRESS",
  FileIngestionFailed = "FILE_INGESTION_FAILED",
  TransferDetectionInProgress = "TRANSFER_DETECTION_IN_PROGRESS",
  TransferDetectionFailed = "TRANSFER_DETECTION_FAILED",
  DeleteInProgress = "DELETE_IN_PROGRESS",
  DeleteFailed = "DELETE_FAILED",
  Converted = "CONVERTED",
  MoveToSourceInProgress = "MOVE_TO_SOURCE_IN_PROGRESS",
  MoveToSourceFailed = "MOVE_TO_SOURCE_FAILED",
}

const sourceStatusSchema = zodLaxEnum([
  SourceStatus.Initialized,
  SourceStatus.Ready,
  SourceStatus.SyncInProgress,
  SourceStatus.SyncFailed,
  SourceStatus.NormalizationInProgress,
  SourceStatus.NormalizationFailed,
  SourceStatus.FileIngestionInProgress,
  SourceStatus.FileIngestionFailed,
  SourceStatus.TransferDetectionInProgress,
  SourceStatus.TransferDetectionFailed,
  SourceStatus.DeleteInProgress,
  SourceStatus.DeleteFailed,
  SourceStatus.Converted,
  SourceStatus.MoveToSourceInProgress,
  SourceStatus.MoveToSourceFailed,
]);

export const baseSourceSchema = z.object({
  source: z.string(),
  sourceType: z.nativeEnum(FileRequestSourceType),
  version: z.number(),
  createdDate: z.string().datetime(),
  label: z.string(),
  // Remove when `isCurrent` is not being returned to the UI
  isCurrent: z.number().optional(),
  integrationId: z.string(),
  caseId: z.string(),
  jobId: z.string(),
  // Remove when `isActive` is not being returned to the UI
  isActive: z.number().optional(),
  status: sourceStatusSchema,
  totalTransactions: z.number(),
  walletAddress: z.string().optional(),
});

export const errorTypesSchema = z.enum(["EMPTY_ROW", "TXN_LIMIT_REACHED"]);

const emptyRowErrorSchema = z.object({
  type: z.literal(errorTypesSchema.Enum.EMPTY_ROW),
  data: z.object({
    rowNumber: z.string(),
  }),
});

const txnLimitReachedErrorSchema = z.object({
  type: z.literal(errorTypesSchema.Enum.TXN_LIMIT_REACHED),
  data: z.object({
    limit: z.string(),
  }),
});

export const sourceErrorSchema = z.discriminatedUnion("type", [
  emptyRowErrorSchema,
  txnLimitReachedErrorSchema,
]);

export const fileSchema = baseSourceSchema.extend({
  fileName: z.string().optional(),
  subpoenaType: z.string().optional(),
  validationCounts: z
    .object({
      failureCount: z.number(),
      successfulCount: z.number(),
    })
    .optional(),
  errorFileSignedUrl: z.nullable(z.string().url()).optional(),
  parsedErrorFileSignedUrl: z.nullable(z.string().url()).optional(),
  rawFileSignedUrl: z.nullable(z.string().url()).optional(),
  errorMessage: z.string().optional(),
  errorDetails: sourceErrorSchema.optional().nullable(),
  csvValidationErrorsSignedUrl: z.string().optional(),
});

export type FileUpload = z.infer<typeof fileSchema>;

export const getFilesApiResponseSchema = z.object({
  sources: z.array(fileSchema),
  continuationToken: z.string().optional().nullable(),
});

export const getFilesSchema = z.array(fileSchema);

export type GetFilesApiResponse = z.infer<typeof getFilesApiResponseSchema>;

export type GetFilesResponse = z.infer<typeof getFilesSchema>;

export type GetFilesRequest =
  | { sourceType: FileRequestSourceType; continuationToken?: string }
  | { sourceType?: FileRequestSourceType; continuationToken: string };
